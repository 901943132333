import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {MatDialogRef} from "@angular/material/dialog";
import {globalValidators} from "../../../configs/global-validators";
import {ApplicationsService} from "../../pages/applications/applications.service";
import {GrowlService} from "../../services/growl.service";
import {ErrorService} from "../../services/error.service";

@Component({
    selector: 'app-new-application-modal',
    templateUrl: './new-application-modal.component.html',
    styleUrls: ['./new-application-modal.component.scss']
})
export class AppNewApplicationModalComponent implements OnInit {
    loading: boolean = false;

    applicationForm = this.formBuilder.nonNullable.group({
        name: ['', [Validators.required]],
        categoryId: ['', [Validators.required]],
        bankClabe: ['', [Validators.required, Validators.pattern(globalValidators.BANK_CLABE_VALIDATOR)]],
        plan: ['', [Validators.required]],
    });

    plans: any[] = [];

    userForm = this.formBuilder.nonNullable.group({
        email: ['', [Validators.required, Validators.email]],
        userName: ['', [Validators.required]],
        middleName: ['', []],
        lastName: ['', []],
    });

    providerForm = this.formBuilder.nonNullable.group({
        enefevo: [{value: true, disabled: true}, ],
        pespay: false,
    });

    constructor(private formBuilder: FormBuilder,
                private applicationsService: ApplicationsService,
                private growlService: GrowlService,
                private errorService: ErrorService,
                public dialogRef: MatDialogRef<AppNewApplicationModalComponent>) {
    }

    ngOnInit(): void {
        this.applicationsService.getPlans().subscribe((plans) => {
            if (Array.isArray(plans) && plans.length > 0) {
                this.plans = plans;
                this.applicationForm.patchValue({
                    plan: plans[0].id
                });
            } else {
                (this.applicationForm as FormGroup).removeControl('plan');
            }
        });
    }

    get userFormControls() {
        return this.userForm.controls;
    }

    get applicationFormControls() {
        return this.applicationForm.controls;
    }

    onCancel() {
        this.dialogRef.close();
    }

    onCreateApplication() {
        this.loading = true;
        const providerRawValue: any = this.providerForm.getRawValue();
        const providers = [];
        for (const property in this.providerForm.getRawValue()) {
            if (typeof providerRawValue[property] === 'boolean' && providerRawValue[property] === true) {
                providers.push(property);
            }
        }
        const request = Object.assign(
            this.applicationForm.getRawValue(),
            this.userForm.getRawValue(),
            { providers }
        );

        this.applicationsService.register(request).subscribe(() => {
            this.growlService.show('success', 'Aplicación registrada.');
            this.loading = false;
            this.dialogRef.close('SUCCESS');
        }, (error) => {
            console.error('Error calling TCPay API', error);
            this.growlService.show('error', this.errorService.handleError(error));
            this.loading = false;
        });

    }

    validateBankClabe(control: FormControl) {
        const numericValue = control.value.replace(/[^0-9]/g, '');
        if (control.value !== numericValue) {
            this.applicationForm.patchValue({
                bankClabe: numericValue
            });
        }

        if (!globalValidators.BANK_CLABE_VALIDATOR.test(control.value)) {
            control.setErrors({'pattern': true});
            control.markAsTouched();
            control.markAsDirty();
        }
    }
}
