<div class="p-b-24 overflow-hidden">
  <div style="border-bottom: 1px solid var(--primary); padding-bottom: 10px;">
    <div class="row">
      <div class="col-lg-8 col-sm-6" style="display: flex; align-items: center;">
        <div *ngIf="pageInfo?.['backNavigation']" style="display: flex; align-items: center;">
          <button mat-icon-button aria-label="expand row" (click)="goBack()">
            <mat-icon>keyboard_arrow_left</mat-icon>
          </button>
        </div>
        <h4 class="page-title m-0 f-s-18 f-w-500">
          {{ pageInfo?.['title'] }}
        </h4>
      </div>
      <div
        class="col-lg-4 col-sm-6 text-right position-relative align-items-center justify-content-end gap-20 d-none d-sm-flex"
      >
        <div class="d-flex align-items-center">
          <div>
            <span class="text-uppercase f-s-12">Este Mes</span>
            <h6 class="text-primary m-0 f-s-18 f-w-400">${{(currentMonth | number) || 0}}</h6>
          </div>
          <div class="d-flex flex-column m-l-8">
            <apx-chart
              [series]="breadcrumbOption.series"
              [chart]="breadcrumbOption.chart"
              [dataLabels]="breadcrumbOption.dataLabels"
              [plotOptions]="breadcrumbOption.plotOptions"
              [yaxis]="breadcrumbOption.yaxis"
              [legend]="breadcrumbOption.legend"
              [fill]="breadcrumbOption.fill"
              [stroke]="breadcrumbOption.stroke"
              [tooltip]="breadcrumbOption.tooltip"
              [xaxis]="breadcrumbOption.xaxis"
              [grid]="breadcrumbOption.grid"
            >
            </apx-chart>
          </div>
        </div>
        <!-- 2 -->
        <div class="d-flex align-items-center">
          <div>
            <span class="text-uppercase f-s-12">Mes Anterior</span>
            <h6 class="text-accent m-0 f-s-18 f-w-400">${{(lastMont | number) || 0}}</h6>
          </div>
          <div class="d-flex flex-column m-l-8">
            <apx-chart
              [series]="breadcrumb2Option.series"
              [chart]="breadcrumb2Option.chart"
              [dataLabels]="breadcrumb2Option.dataLabels"
              [plotOptions]="breadcrumb2Option.plotOptions"
              [yaxis]="breadcrumb2Option.yaxis"
              [legend]="breadcrumb2Option.legend"
              [fill]="breadcrumb2Option.fill"
              [stroke]="breadcrumb2Option.stroke"
              [tooltip]="breadcrumb2Option.tooltip"
              [xaxis]="breadcrumb2Option.xaxis"
              [grid]="breadcrumb2Option.grid"
            >
            </apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
