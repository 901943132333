import {Component, Inject, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {IWebhook} from "../../pages/account-settings/webhook.interface";

@Component({
    selector: 'app-new-webhook-modal',
    templateUrl: './new-webhook-modal.component.html',
    styleUrls: ['./new-webhook-modal.component.scss']
})
export class AppNewWebhookModalComponent {
    loading: boolean;
    alert: { type: string; message: string } | null;

    constructor(private formBuilder: FormBuilder,
                public dialogRef: MatDialogRef<AppNewWebhookModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: IWebhook) {
        this.loading = false;
        this.alert = null;
    }

    webhookForm = this.formBuilder.nonNullable.group({
        name: [this.data?.name || '', [Validators.required]],
        url: [this.data?.url || '', [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]],
        headers: this.fillHeadersFormGroup(),
    });

    createHeaderFormGroup(): FormGroup {
        return this.formBuilder.nonNullable.group({
            name: ['', Validators.required],
            value: ['', Validators.required]
        });
    }

    fillHeadersFormGroup(): FormArray {
        const headersArray =  this.formBuilder.array([]) as FormArray;
        this.data?.headers.forEach((header) => {
            const control = this.formBuilder.nonNullable.group({
                name: [header.name, Validators.required],
                value: [header.value, Validators.required]
            });
            headersArray.push(control);
        });

        return headersArray;
    }

    get formControls() {
        return this.webhookForm?.controls;
    }

    get formControlsResult() {
        const headersResult = [];
        for (let header of this.webhookForm.controls.headers.controls) {
            const headerRawValue = header.getRawValue() as { name: string, value: string };
            headersResult.push(headerRawValue);
        }

        return {
            name: this.formControls.name.value,
            url: this.formControls.url.value,
            headers: headersResult
        }
    }

    onAlertClosed() {
        this.alert = null;
    }

    onAddHeader() {
        const headersArray = this.formControls.headers as FormArray;
        headersArray.push(this.createHeaderFormGroup());
    }

    onRemoveHeader(controlIndex: number) {
        const headersArray = this.formControls.headers as FormArray;
        headersArray.removeAt(controlIndex);
    }

    onCancel() {
        this.dialogRef.close();
    }
}
